import React, { useEffect } from 'react';
import { useBi } from '@wix/yoshi-flow-editor';
import { navigateToSection } from '@wix/bi-logger-olo-client/v2';

import {
  TabsAlignment as ALIGNMENT,
  TabsSkin as SKIN,
  TabsVariant as VARIANT,
  TabItem,
} from 'wix-ui-tpa';

import Tabs from '../Tabs';
import styles from './SectionTabs.scss';
import { useHistory } from 'react-router-dom';

interface SectionTabItem extends TabItem {
  slug: string;
}

export interface SectionTabsProps {
  sections: SectionTabItem[];
  dataHook?: string;
  className?: string;
  isRTL?: boolean;
  isMobile?: boolean;
  selectedSectionId?: string;
}

const SectionTabs: React.FC<SectionTabsProps> = ({
  sections,
  isRTL,
  className,
  dataHook,
  selectedSectionId: initialSelectedSectionId,
}) => {
  const [selectedSectionId, setSectionId] = React.useState(initialSelectedSectionId || sections[0]?.id);
  const biLogger = useBi();
  const history = useHistory();

  useEffect(() => {
    if (initialSelectedSectionId) {
      setSectionId(initialSelectedSectionId);
    }
  }, [initialSelectedSectionId]);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Tabs
        data-hook={dataHook}
        aria-label={'Sub menus'}
        activeTabIndex={sections.findIndex((e) => e.id === selectedSectionId)}
        alignment={isRTL ? ALIGNMENT.right : ALIGNMENT.left}
        items={sections}
        onTabClick={(selectedTabIndex) => {
          const sectionId = sections[selectedTabIndex].id;
          const sectionName = sections[selectedTabIndex].title;
          const sectionSlug = sections[selectedTabIndex].slug;
          biLogger.report(navigateToSection({ sectionId, sectionName }));
          if (sectionId) {
            history.push(`/${sectionSlug}`);
            setSectionId(sectionId);
          }
        }}
        skin={SKIN.fullUnderline}
        variant={VARIANT.fit}
      />
    </div>
  );
};

export default SectionTabs;
